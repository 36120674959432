.ember-modal-overlay.translucent {
  background-color: rgba(0, 0, 0, 0.44) !important;
}

.ember-modal-overlay {
  z-index: 20000 !important;
  height: 200vh;
}

.ember-modal-dialog {
  height: 500px;
  width: 800px;
  border-radius: 0px;
  background-color: #fff;
  box-shadow: 0 0 10px #222;
  overflow-y: auto;
}


.modal-title {
  padding-bottom: 15px;
  //border-bottom: 1px solid rgb(226, 226, 226);
}
