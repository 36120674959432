body{
  overflow-x: hidden;
}

.ribbon{
  position: absolute; 
  top: 42px;
  width: 200px;
  padding: 1px 0;
  background: #000;
  color: #eee;

  -moz-box-shadow: 0 0 10px rgba(0,0,0,0.5);
  -webkit-box-shadow: 0 0 10px rgba(0,0,0,0.5);
  box-shadow: 0 0 10px rgba(0,0,0,0.5);
}

.ribbon.left{
  left: -42px;
  -moz-transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.ribbon.right{
  right: -42px;
  -moz-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.ribbon a, 
.ribbon a:visited, 
.ribbon a:active, 
.ribbon a:hover{
  display: block;
  padding: 1px 0;
  height: 24px;
  line-height: 24px;

  color: inherit;  
  text-align: center;
  text-decoration: none;
  font-family: 'Cabin Condensed', sans-serif;
  font-size: 16px;
  font-weight: 500;

  border: 1px solid rgba(255,255,255,0.3);

  -moz-text-shadow: 0 0 10px rgba(0,0,0,0.31);
  -webkit-text-shadow: 0 0 10px rgba(0,0,0,0.31);
  text-shadow: 0 0 10px rgba(0,0,0,0.31);
}

.ribbon.black{
  background: #000;
}

.ribbon.red{
  background: #c00;
}

.ribbon.blue{
  background: #09e;
}

.ribbon.green{
  background: #0a0;
}

.ribbon.orange{
  background: #d80;
}

.ribbon.purple{
  background: #c0c;
}

.ribbon.grey{
  background: #888;
}

.ribbon.white{
  background: #eee;
  color: black;
}
.ribbon.white a{
  border: 2px dotted rgba(100,100,100,0.2);
}
